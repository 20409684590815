import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";  
import ProductsNew from "../components/goods/ProductsNew";

const VyhledavaniPage = ({ location }) => {
   
    return (
            <>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Plyšáci | Plyšovník</title> 
                </Helmet>
                <Layout location={location}> 
            

               

                        <ProductsNew location={location} />
                  
                </Layout>
            </>

    )
}

export default VyhledavaniPage
